import { Ticket } from "./journeyResults";

export type Booking = {
  id: number;
  deviceToken: string;
  customerName: null | string;
  customerId: null | string;
  delivery: null | string;
  nlc: string;
  brand: string;
  channel: string;
  referrer: null | string;
  campaign: null | string;
  collectionReference: null | string;
  email: null | string;
  telephoneNumber: null | string;
  totalPrice: string;
  leftToPay: string;
  amendPriceDiff: number;
  state: string;
  creationDate: string;
  purchaseDate: null | string;
  lastUpdated: null | string;
  refundRejectionDate: null | string;
  refundRejectReason: null | string;
  trips: string[];
  expiry: string;
  payments: {
    cards: null;
    warrants: null;
    paypal: null;
    applePay: null;
    googlePay: null;
    amazonPay: null;
    hostedCards: null;
  };
  sundries: null | string;
  amendedTickets: any[]; // You might want to replace `any` with a more specific type
  refundCanBeAttemptedOnline: null | string;
  refundCanBeAttemptedOffline: null | string;
  refundsLocked: boolean;
  discount: null | string;
  isInterop: boolean;
  totalRewardPoints: number;
  rewardPointUid: null | string;
  rewardName: null | string;
  rewardExpiryDate: null | string;
  promotionalDiscount: null | string;
  outwardJourney: BookedJourney;
  returnJourney?: BookedJourney;
  tickets: Ticket[];
};

export type BookedLeg = {
  validFrom: string;
  validTo: string;
  validityPeriod: string;
  legs: Leg[];
};

export type BookedJourney = {
  legs: BookedLeg;
  departureTime: string;
  arrivalTime: string;
  outwardValidityFrom: string;
  outwardValidityTo: string;
  fareType: string;
  routeName: string;
  routeDescription: string;
};

interface Leg {
  id: number;
  origin: string;
  destination: string;
  departure: string;
  arrival: string;
  transferTime: null | string;
  mode: string;
  toc: string;
  seats: any[]; // You might want to replace `any` with a more specific type
  reservable: string;
  facilities: string;
  rsid: string;
  tuid: string;
  isTemporaryTrain: boolean;
}

export enum BookingStatus {
  Incomplete = "Incomplete",
  Processing = "Processing",
  Fulfilled = "Fulfilled",
}
