import axiosHttp from './axios';

import { SET_RAILCARDS } from "./constants";

// Selectors
import { sortBy } from "lodash-es";

// Data
// import localRailcards from '../assets/offline-data/railcards.json'

export const setRailcards = (railcards) => (dispatch) =>
  dispatch({
    type: SET_RAILCARDS,
    railcards,
  });

export const getRailcards = () => (dispatch, getState) => {
  
  axiosHttp.get(`/rail-cards`)
    .then(({ data }) => {
      const sortedRailcards = sortBy(data, [
        function (railcard) {
          return railcard?.name;
        },
      ]);
      dispatch(setRailcards(sortedRailcards));
    })
    .catch((ex) => {
      console.log("[getRailcards] Exception : ", ex);
    });
};
