// https://blog.javascripttoday.com/blog/trie-data-structure/ Referred from blog for trie data structure, searching a word across rather than find the start with
//https://www.toptal.com/java/the-trie-a-neglected-data-structure
// will move to npm plugin soon for TRIE  https://github.com/joshjung/trie-search

//removed all custom code replaced with node library trie
import TrieSearch from "trie-search";
import { filter, some } from "lodash-es";

const MAXIMUM_SUGGESTIONS = 10;
let trie = null;

export function insertStationNames(stationNameArray) {
  const options = {
    cache: true,
  };
  trie = new TrieSearch(["name", "crs"], options);
  trie.addAll(stationNameArray);
}

export function getSuggestedStations(stationName) {
  // console.log('stationName', stationName)

  let suggestions = trie?.search(stationName) || [];

  // console.log(suggestions)
  //to remove Station Name which has //underground keyword
  let stringsToCheck = ["Underground", "Travelcard"]; // Add the strings you want to check here

  let results = filter(suggestions, function (station) {
    return !some(stringsToCheck, function (string) {
      return station?.name?.includes(string);
    });
  });

  const sortedData = results.toSorted((a, b) => {
    // Check if a and b have operator 'SE'
    const aHasSEOperator = a.operator === "SE";
    const bHasSEOperator = b.operator === "SE";

    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    const keywordIndexA = nameA.indexOf(stationName.toLowerCase());
    const keywordIndexB = nameB.indexOf(stationName.toLowerCase());

    if (keywordIndexA === 0 && keywordIndexB !== 0) {
      return -1; // a comes before b
    } else if (keywordIndexA !== 0 && keywordIndexB === 0) {
      return 1; // b comes before a
    } else {
      if (aHasSEOperator && !bHasSEOperator) {
        return -1; // a comes before b (a has 'SE' operator, b does not)
      } else if (!aHasSEOperator && bHasSEOperator) {
        return 1; // b comes before a (b has 'SE' operator, a does not)
      } else {
        // If both have 'SE' operator or neither do, sort based on the position of the keyword
        if (keywordIndexA < keywordIndexB) {
          return -1; // a comes before b (lower index)
        } else if (keywordIndexA > keywordIndexB) {
          return 1; // b comes before a (higher index)
        } else {
          return 0; // Keywords are at the same index, maintain the current order
        }
      }
    }
  });

  // console.log(sortedData)
  // suggestions = sortBy(suggestions, [function(station) { return station?.name; }]);
  return sortedData.slice(0, MAXIMUM_SUGGESTIONS);
}
