import styles from "./SearchInput.module.scss";
import { memo } from "react";
import SearchIcon from "../../assets/SearchIcon";
import classNames from "classnames";
import ClearActive from "../../assets/ClearActive";

type SearchInputProps = {
  placeHolder: string;
  onClick?: () => void;
  onChange?: () => void;
  onClearClicked?: () => void;
  variant?: "big" | "small";
  value?: string;
};

function SearchInput({
  placeHolder,
  onClick = () => {},
  onChange = () => {},
  onClearClicked = () => {},
  variant = "big",
  value = "",
}: Readonly<SearchInputProps>) {
  return (
    <label htmlFor="search-input" className={styles.label}>
      <input
        onClick={onClick}
        name="search-input"
        readOnly
        placeholder={placeHolder}
        aria-label="copy-button"
        onChange={onChange}
        value={value}
        className={classNames(styles.input, styles[variant])}
      />
      {value.length > 0 ? (
        <ClearActive
          className={classNames(styles.inputIcon, styles[variant])}
          onClick={onClearClicked}
        />
      ) : (
        <SearchIcon
          onClick={onClick}
          className={classNames(styles.inputIcon, styles[variant])}
        />
      )}
    </label>
  );
}

export default memo(SearchInput);
