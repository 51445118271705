import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// Reducers
import app from "./app";
import stations from "./stations";
import journeyResults from "./journeyResults";
import bookings from "./bookings";
import railcards from "./railcards";
import journeyPlanner from "./journeyPlanner";
import { History } from "history";

// Here we'll combine all the reducer in our app
const createRootReducer = (history: History) =>
  combineReducers({
    app,
    stations,
    journeyPlanner,
    journeyResults,
    bookings,
    railcards,
    router: connectRouter(history),
  });

export default createRootReducer;
