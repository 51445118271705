import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 400 140"
    {...props}
  >
    <path
      d="M60 15c8.1 0 15.9 3.1 21.8 8.6l41.6 38.8c5.9 5.5 13.7 8.6 21.8 8.6H368c8.8 0 16 7.2 16 16v53h16V0H0v15h60z"
      style={{
        fill: "#1e1e50",
      }}
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
