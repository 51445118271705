import styles from "./PopularStation.module.scss";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect } from "react";

import {
  selectPopularStations,
  selectStations,
} from "../../selectors/stations";

import {
  setArrivalStation,
  setJourneyType,
} from "../../actions/journeyplanner";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { SINGLE } from "../../actions/constants";
import { appOpenJourneyPlanner } from "../../actions/app";
import { getPopularStations } from "../../actions/stations";
import Caret from "../../assets/Caret";
import { useTranslation } from "react-i18next";

const PopularStations = React.memo(() => {
  const dispatch = useDispatch();

  const popularStations = useSelector(selectPopularStations);
  const stations = useSelector(selectStations);
  const { t } = useTranslation();

  useEffect(() => {
    let timer = Math.round(Math.random() * 15);
    if (popularStations.length === 0) {
      setTimeout(() => {
        dispatch(getPopularStations());
      }, timer * 10000);
    }
  }, [dispatch, popularStations]);

  const handleStationSelect = useCallback(
    (type, selectedPopularStation) => {
      let mergedStation = stations.find(
        (station) => station?.nlc === selectedPopularStation?.nlc
      );

      // trying to find the missing CRS code for that selected popular station,
      //set the type of journey and the station object to the state
      dispatch(setArrivalStation(mergedStation));
      dispatch(setJourneyType(type));
      dispatch(appOpenJourneyPlanner(true));
    },
    [stations, dispatch]
  );

  return (
    <div className={styles.popularStationWrapper}>
      <p className={styles.title}> {t("home.subtitle")}</p>

      {popularStations.length === 0 ? (
        <SkeletonTheme baseColor="#dcd9d9" highlightColor="#f5f5f5">
          <Skeleton
            wrapper={popularStationsLoader}
            count={14}
            containerClassName={styles.popularStationGrid}
          />
        </SkeletonTheme>
      ) : (
        <div className={styles.popularStationGrid}>
          {popularStations.map((station) => (
            <div
              className={styles.popularStation}
              key={station?.nlc}
              onClick={() => handleStationSelect(SINGLE, station)}
            >
              <p className={styles.popularStationName}>{station?.name}</p>
              <Caret />
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default PopularStations;

function popularStationsLoader({ children }) {
  return <div className={styles.popularStation}>{children}</div>;
}
