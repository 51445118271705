import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./JourneyResults.css";
// Styles
import styles from "./JourneyResults.module.scss";

// Components
import JourneyBar from "../../JourneyBar/JourneyBar";

// Actions
import "react-loading-skeleton/dist/skeleton.css";
import {
  setIsLoadingJourneyResults,
  getJourneyResults,
  JourneyResultsDispatch,
} from "../../../actions/journeyResults";
// Selectors
import {
  selectLoadingState,
  selectLegSolutions,
  selectFromStation,
  selectToStation,
  selectDateTime,
  selectAreAllETicketsAvailable,
  selectFastestService,
} from "../../../selectors/journeyResults";

import {
  selectPassengerExpression,
  selectRailcardNames,
} from "../../../selectors/journeyPlanner";
import UnavailableETicket from "../UnavailableETickets/UnavailableETickets";
import {
  AppDispatch,
  appOpenJourneyPlanner,
  appOpenOutboundJourneyResults,
} from "../../../actions/app";
import Caret from "../../../assets/Caret";
import classNames from "classnames";
import {
  JourneyPlannerDispatch,
  setOutwardEarlierJourneys,
  setReturnEarlierJourneys,
} from "../../../actions/journeyplanner";
import { LegSolution } from "../../../types/journeyResults";
import { Station, ToknDate } from "../../../types/journeyPlanner";
import { selectCurrentState } from "../../../selectors/app";
import { AppGlobalStates } from "../../../types/app";
import { SkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import { TextBold } from "../../StyledTags/Text";

interface JourneyResultsProps {}

const JourneyResults: React.FC<JourneyResultsProps> = (): React.ReactNode => {
  const dispatch = useDispatch<JourneyResultsDispatch>();
  const appDispatch = useDispatch<AppDispatch>();
  const jpDispatch = useDispatch<JourneyPlannerDispatch>();

  const loadingState = useSelector(selectLoadingState);
  const passengerExpression = useSelector(selectPassengerExpression);
  const legSolutions: LegSolution[] = useSelector(selectLegSolutions);
  const fromStation: Station = useSelector(selectFromStation);
  const toStation: Station = useSelector(selectToStation);
  const dateTime: ToknDate = useSelector(selectDateTime);
  let railcardNames = useSelector(selectRailcardNames);
  const currentState = useSelector(selectCurrentState);
  const fastService = useSelector(selectFastestService);
  const allETicketCollectionAvailable = useSelector(
    selectAreAllETicketsAvailable
  );

  if (railcardNames.length > 0) {
    railcardNames += " ,";
  }

  const onAmendSearch = useCallback(() => {
    if (currentState === AppGlobalStates.OutboundJourneysResult) {
      appDispatch(appOpenJourneyPlanner());
      dispatch(setIsLoadingJourneyResults(false));
    } else {
      appDispatch(appOpenOutboundJourneyResults());
    }
  }, [dispatch, appDispatch, currentState]);

  const handleLaterBtnClick = useCallback(() => {
    if (currentState === AppGlobalStates.OutboundJourneysResult) {
      jpDispatch(setOutwardEarlierJourneys(false));
    } else {
      jpDispatch(setReturnEarlierJourneys(false));
    }
  }, [jpDispatch, currentState]);

  const handleEarlierBtnClick = useCallback(() => {
    if (currentState === AppGlobalStates.OutboundJourneysResult) {
      jpDispatch(setOutwardEarlierJourneys(true));
    } else {
      jpDispatch(setReturnEarlierJourneys(true));
    }
  }, [jpDispatch, currentState]);

  return !allETicketCollectionAvailable ? (
    <UnavailableETicket />
  ) : (
    <div className={styles.screenMidSection}>
      <div className={styles.headerSecWrapper}>
        <div className={styles.headerSection}>
          <div className={styles.journeyDetailsSec}>
            <TextBold size="21px" color="#0B78C1">
              {currentState === AppGlobalStates.OutboundJourneysResult
                ? "Outbound"
                : "Return"}
            </TextBold>
            <div className={styles.stationHeaders}>
              <span className={styles.textEllipse}>
                <b>{fromStation?.name}</b> {fromStation?.crs}
              </span>
              <div style={{ margin: "0 15px" }}>&rarr;</div>
              <span className={styles.textEllipse}>
                <b>{toStation?.name}</b> {toStation?.crs}
              </span>
            </div>
          </div>
          <div onClick={onAmendSearch} className={styles.journeyDetailsBtn}>
            {currentState === AppGlobalStates.ReturnJourneysResult
              ? " Back"
              : "Amend search"}
          </div>
        </div>
        <div className={styles.travelDescription}>
          <i>
            {`${passengerExpression}, ${railcardNames} ${dateTime?.dayName}
              ${dateTime?.dayString} ${dateTime?.monthName} ${dateTime?.year} `}
          </i>
        </div>
      </div>
      <div className={styles.sectionBottom}>
        {!loadingState ? (
          <SkeletonLoader />
        ) : (
          <>
            <div
              className={styles.earlierLater}
              onClick={handleEarlierBtnClick}
            >
              <Caret fill="#000000" className={styles.caret} /> Earlier
            </div>
            {legSolutions.length !== 0 ? (
              <div className={styles.journeyResultsSection}>
                {Object.entries(legSolutions).map(([, legSolution], index) => {
                  return (
                    <JourneyBar
                      key={legSolution.id}
                      index={index}
                      fastestService={fastService === legSolution.duration}
                      legSolution={legSolution}
                    />
                  );
                })}
              </div>
            ) : (
              <>
                <div></div>
                <div className={styles.noServiceHomePage}>
                  No Journeys Found
                </div>
                <div></div>
              </>
            )}
            <div className={styles.earlierLater} onClick={handleLaterBtnClick}>
              <Caret
                fill="#000000"
                className={classNames(styles.caret, styles.inverse)}
              />
              Later
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JourneyResults;
