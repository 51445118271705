// import axios from 'axios'

import { SET_STATIONS, SET_POPULAR_STATIONS } from "./constants";

// Data
import popularStationsCached from "../assets/offline-data/popularstations.json";
import { insertStationNames } from "../TrieSearch";
import stationsCached from "../assets/offline-data/stations.json";
import { ActionTypes, StationState } from "../types/stations";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { CallHistoryMethodAction } from "connected-react-router";
import { Station } from "../types/journeyPlanner";
import { sortBy } from "lodash-es";
export type ThunkResult<R> = ThunkAction<R, StationState, undefined, any>;

export type StationsDispatch = ThunkDispatch<
  StationState,
  undefined,
  ActionTypes | CallHistoryMethodAction
>;

export const setStations =
  (data: Station[]): ThunkResult<void> =>
  (dispatch: StationsDispatch) => {
    dispatch({
      type: SET_STATIONS,
      data,
    });
  };

export const setPopularStations =
  (data: Station[]): ThunkResult<void> =>
  (dispatch: StationsDispatch) => {
    dispatch({
      type: SET_POPULAR_STATIONS,
      data,
    });
  };

// Actions - API calls
//Get the list of stations list and dispatch state
export const getStations = () => (dispatch: any, getState: any) => {
  let data = stationsCached;
  // const token = selectAPIToken(getState())
  // const url = selectAPIUrl(getState())
  // axios.get(`${url}/stations`, {
  //   headers: { Authorization: token }
  // }).then(({ data }) =>
  // {
  const filteredStations = data.map((station) => ({
    nlc: station?.nlc,
    name: station?.name,
    crs: station?.crs,
    operator: station?.operator,
  }));
  //once we receive the station list - store in global trie search DS and forget
  //use suggestion func to query the station object with either CRS or any segment of the station name
  //to get the relavant staion object{name,crs,nlc}
  insertStationNames(filteredStations);
  dispatch(setStations(filteredStations));
  // }).catch((error) => {
  //   console.error(error)
  // })
};

//Get the Populat station list for 5093
// In Prod ping WHO am i , get the staion code then fetch Popular API
export const getPopularStations = () => (dispatch: any, getState: any) => {
  let data = popularStationsCached; // just caching for dmeo purpose ..remove once it goes to production
  // const token = selectAPIToken(getState())
  // const url = selectAPIUrl(getState())

  // axios.get((`${url}/popular-destinations?origin=5004`),
  //   {
  //     headers: { Authorization: token }
  //   }
  // ).then(({data}) =>
  // {
  const sortedPopularStationsList = sortBy(data, ["name"]);

  dispatch(setPopularStations(sortedPopularStationsList));
  // }).catch((error) => {
  //   console.log(error)
  //   dispatch(setPopularStations([]));
  // })
};
