import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styles from "./SekeletonLoader.module.scss";

export const SkeletonLoader = () => {
  function journeyResultsLoader({ children }) {
    return <div className={styles.journeyResultsWrapper}>{children}</div>;
  }
  return (
    <SkeletonTheme
      baseColor="#dcd9d9"
      highlightColor="#e5e5e5"
      borderRadius={22}
    >
      <Skeleton
        wrapper={journeyResultsLoader}
        count={5}
        containerClassName={styles.skeleton}
      />
    </SkeletonTheme>
  );
};
