import React, { useCallback } from "react";
import styles from "./UnavailableETickets.module.scss";
import NoTicketFound from "../../../assets/no-eticket-circle.svg?react";
import Button from "../../Button/Button";
import { AppDispatch, appOpenJourneyPlanner } from "../../../actions/app";
import { useDispatch } from "react-redux";

const UnavailableETicket: React.FC<{}> = (): React.ReactElement => {
  const appDispatch = useDispatch<AppDispatch>();

  const onTimeSelection = useCallback(() => {
    appDispatch(appOpenJourneyPlanner(true));
  }, [appDispatch]);

  return (
    <div className={styles.eTicketCollectionScreen}>
      <NoTicketFound />
      <div className={styles.msgTitle}>eTickets unavailable</div>
      <div className={styles.description}>
        No eTickets are currently available for the selected journey.
      </div>
      <Button data="Amend Search" onSelectFunc={onTimeSelection} />
      <div className={styles.msgBriefSection}>
        <b>Not what you expected ?</b>
        <p className={styles.reason}>
          This may be a result of a known issue with the data we receive
          incorrectly omitting some stations that lack barcode reading
          facilities. (id:5266938087).
        </p>
      </div>
    </div>
  );
};

export default UnavailableETicket;
