import styles from "./EditBox.module.scss";

import React, { useMemo } from "react";
import Date from "../../assets/Date";
import Passengers from "../../assets/Passengers";
import Railcard from "../../assets/Railcard";
import Caret from "../../assets/Caret";
import { DateTimePicker } from "../../types/journeyPlanner";
import classNames from "classnames";
import { TextNoWrap } from "../../components/StyledTags/Text";

interface EditBoxProps {
  label: string;
  placeHolder?: string;
  onClickCallback?: (
    isOpen: boolean,
    dateTimePickedDate: DateTimePicker | null
  ) => void;
  iconName?: string | null;
  textOnly?: boolean;
}

const EditBox: React.FC<EditBoxProps> = ({
  label,
  placeHolder,
  onClickCallback,
  iconName = null,
  textOnly = false,
}) => {
  const renderIcon = useMemo(() => {
    switch (iconName) {
      case "date":
        return <Date />;
      case "passengers":
        return <Passengers />;
      case "railcard":
        return <Railcard />;
      default:
        return null;
    }
  }, [iconName]);
  return (
    <div className={styles.editBoxWrapper}>
      {renderIcon}
      {textOnly ? (
        <div className={styles.editBox}>
          <div className={styles.boldText}>{label}</div>
        </div>
      ) : (
        <div
          className={classNames(styles.editBox, styles.active)}
          onClick={() => onClickCallback(true, null)}
        >
          <div className={styles.boldText}>{label}</div>
          <div className={styles.rightSegment}>
            <span>
              <TextNoWrap>{placeHolder}</TextNoWrap>
            </span>
            <Caret fill="#1E1E50" />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBox;
