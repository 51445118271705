import React from "react";

function Return() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="#fff"
        data-name="Ellipse 162"
      ></circle>
      <path
        fill="#1e1e50"
        d="M.9 14.351l2.842 2.842.8.807 1.61-1.61-.8-.8-.9-.9h11.466v-2.279H4.452l.9-.9.8-.8L4.548 9.1l-.8.8L.9 12.741l-.8.807.8.8zm14.116-9.1l.8-.8-.8-.8-2.84-2.844L11.37 0 9.76 1.61l.8.8.9.9H0v2.279h11.466l-.9.9-.8.8L11.37 8.9l.8-.8 2.842-2.842z"
        transform="rotate(90 8.624 18.6)"
      ></path>
    </svg>
  );
}

export default React.memo(Return);
