export type ApiCredentials = {
  client_id: string;
  client_secret: string;
  audience: string;
  grant_type: string;
};

export type ApiToken = {
  access_token: string;
  token_type: string;
};

export type ApiConfig = {
  credentials: ApiCredentials;
  url: string;
  authUrl: string;
  token: ApiToken;
};

export type ModalState = boolean;

export type AppState = {
  ready: boolean;
  environment: string;
  modal: ModalState;
  currentState: AppGlobalStates;
};

export type ActionTypes = {
  type: string;
  data:
    | ModalState
    | ApiConfig
    | ApiToken
    | ApiCredentials
    | boolean
    | null
    | AppGlobalStates;
};

export enum AppGlobalStates {
  Default = 0,
  HomePage = 1,
  JourneyPlanner = 2,
  OutboundDateTimePicker = 3,
  ReturnDateTimePicker = 4,
  PassengerPicker = 5,
  RailcardPicker = 6,
  OutboundJourneysResult = 7,
  ReturnJourneysResult = 8,
  PurchaseSummary = 9,
}
