import { useEffect, useState } from "react";

import styles from "./PurchaseSummary.module.scss";
import GroupSaveIcon from "../../../assets/groupsave.svg?react";
import {
  selectJourneyType,
  selectRailcardNames,
  selectOutboundDate,
  selectReturnDate,
  selectPassengerExpression,
} from "../../../selectors/journeyPlanner";
import Collapsible from "react-collapsible";
import useQrCode from "react-qrcode-hook";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RETURN } from "../../../actions/constants";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Passengers from "../../../assets/Passengers";
import classNames from "classnames";
import Railcard from "../../../assets/Railcard";
import ApplePayLogo from "../../../assets/apple-pay_available.svg?react";
import {
  selectAnyTicketHasGroupSave,
  selectAnyTicketHasRailcards,
  selectPickedOutwardBookingLeg,
  selectPickedOutwardPrice,
  selectPickedReturnBookingLeg,
  selectPickedReturnPrice,
} from "../../../selectors/journeyResults";
import { JourneyType, Station, ToknDate } from "../../../types/journeyPlanner";
import { LegSolution, Price } from "../../../types/journeyResults";
import { selectStationByCode } from "../../../selectors/stations";
import { parseHourTimeFromUTC } from "../../../utils/journeyplanner";
import FaresInformation from "../../FareInformation/FareInformation";
import SuccessSVG from "../../../assets/success.svg?react";
import {
  useBookingAPI,
  useLockAndListenBookingAPI,
} from "./PurchaseSummary.hooks";
import { parseBookedSeats, timeout } from "../../../utils/purchaseSummary";
import { AppDispatch, appOpenHome } from "../../../actions/app";

const PurchaseSummary: React.FC = (): React.ReactNode => {
  const { data, loading, error }: APIStates = useBookingAPI();
  const appDispatch = useDispatch<AppDispatch>();
  const isOrderUnLocked: boolean = useLockAndListenBookingAPI(data);

  //console.log("isOrderUnLocked", isOrderUnLocked);

  useEffect(() => {
    async function resetToHome() {
      console.log("isOrderUnLocked inside reset home", isOrderUnLocked);
      if (isOrderUnLocked) {
        await timeout(5000);
        appDispatch(appOpenHome());
      }
    }
    resetToHome();
  }, [appDispatch, isOrderUnLocked]);

  const [extraFareSecVisibleOut, setExtraFareSecVisibleOut] = useState(false);
  const [extraFareSecVisibleRet, setExtraFareSecVisibleRet] = useState(false);

  const railcardsSelectedName: string = useSelector(selectRailcardNames);

  const journeyType: JourneyType = useSelector(selectJourneyType);

  const outboundDate: ToknDate = useSelector(selectOutboundDate);
  const returnDate: ToknDate = useSelector(selectReturnDate);

  const passengerExpression: string = useSelector(selectPassengerExpression);

  const pickedOutboundLeg: LegSolution = useSelector(
    selectPickedOutwardBookingLeg
  );
  const pickedReturnLeg: LegSolution = useSelector(
    selectPickedReturnBookingLeg
  );

  const pickedOutboundPrice: Price = useSelector(selectPickedOutwardPrice);
  const pickedReturnPrice: Price = useSelector(selectPickedReturnPrice);

  const outboundLegOriginStaion: Station = useSelector(
    selectStationByCode(pickedOutboundPrice?.origin)
  );
  const outboundLegDestinationStaion: Station = useSelector(
    selectStationByCode(pickedOutboundPrice?.destination)
  );

  const returnLegOriginStation: Station = useSelector(
    selectStationByCode(pickedReturnPrice?.origin)
  );
  const returnLegDestinationStation: Station = useSelector(
    selectStationByCode(pickedReturnPrice?.destination)
  );

  const isOutwardRailcardApplied: boolean = useSelector(
    selectAnyTicketHasRailcards(pickedOutboundPrice?.tickets)
  );
  const isReturnRailcardApplied: boolean = useSelector(
    selectAnyTicketHasRailcards(pickedReturnPrice?.tickets)
  );

  const isOutwardGroupSaveApplied: boolean = useSelector(
    selectAnyTicketHasGroupSave(pickedOutboundPrice?.tickets)
  );

  const isReturnGroupSaveApplied: boolean = useSelector(
    selectAnyTicketHasGroupSave(pickedReturnPrice?.tickets)
  );
  const isRailcardApplied = isOutwardRailcardApplied || isReturnRailcardApplied;
  const isGroupSaveApplied =
    isOutwardGroupSaveApplied || isReturnGroupSaveApplied;

  const qrCodeImg = useQrCode(
    data &&
      `https://dev-payment.railpay.co.uk/${data?.id}?deviceToken=${data?.deviceToken}`
  );
  console.log(qrCodeImg);
  if (error)
    return <div>Error with Purchase Summary Tok-n {error.message}</div>;

  return (
    <div className={styles.summaryWrapper}>
      <div className={styles.qrCodeWrapper}>
        <div className={styles.scanInfo}>
          {!isOrderUnLocked ? (
            <h1>
              <span>Scan this code</span> to complete purchase on your mobile
              device
            </h1>
          ) : (
            <h1>
              <span>Code scanned</span> Please complete purchase on your mobile
              device
            </h1>
          )}
          <ol>
            <li className={isOrderUnLocked ? styles.checked : ""}>
              {!isOrderUnLocked ? "Scan the QR code" : "QR code scanned"}
            </li>
            <li>
              Pay on your mobile using Apple Pay or Google Pay
              <div className={styles.paymentLogos}>
                <ApplePayLogo />
                <img alt="gPayLogo" src="google-pay.png" />
              </div>
            </li>
            <li>
              Your eTickets will be available immediately on your phone, and can
              be added to your ticket wallet
            </li>
          </ol>
        </div>
        <div className={styles.qrCodeContainer}>
          <div className={styles.qrCode}>
            {loading ? (
              <SkeletonTheme baseColor="#dcd9d9" highlightColor="#e5e5e5">
                <Skeleton containerClassName={styles.qrCodeLoader} />
              </SkeletonTheme>
            ) : (
              <>
                {isOrderUnLocked ? (
                  <SuccessSVG />
                ) : (
                  <img src={qrCodeImg} alt="qrCodeImage" />
                )}
                <h1>This is not your ticket</h1>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.journeyDetailsWrapper}>
        <h2>Summary</h2>
        <div className={styles.summaryContainer}>
          <div className={styles.journeyDetails}>
            <div className={styles.legs}>
              <div className={classNames(styles.box, styles.leg)}>
                <div className={styles.date}>
                  <b>Outbound:</b>
                  {` ${outboundDate?.dayName}
                  ${outboundDate?.dayString} ${outboundDate?.monthName}
                  ${outboundDate?.year}`}
                </div>
                <div>
                  {parseHourTimeFromUTC(
                    pickedOutboundLeg?.departureTime?.adjustedTime
                  )}{" "}
                  {outboundLegOriginStaion?.name}
                </div>
                <div>
                  {parseHourTimeFromUTC(
                    pickedOutboundLeg?.arrivalTime?.adjustedTime
                  )}{" "}
                  {outboundLegDestinationStaion?.name}
                </div>
              </div>
              {journeyType === RETURN && (
                <div className={classNames(styles.box, styles.leg)}>
                  <div className={styles.date}>
                    <b>Return:</b>
                    {` ${returnDate?.dayName}  ${returnDate?.dayString} 
                    ${returnDate?.monthName} ${returnDate?.year}`}
                  </div>
                  <div>
                    {parseHourTimeFromUTC(
                      pickedReturnLeg?.departureTime?.adjustedTime
                    )}{" "}
                    {returnLegOriginStation?.name}
                  </div>
                  <div>
                    {parseHourTimeFromUTC(
                      pickedReturnLeg?.arrivalTime?.adjustedTime
                    )}{" "}
                    {returnLegDestinationStation?.name}
                  </div>
                </div>
              )}
            </div>
            <div className={classNames(styles.box, styles.passengerInfo)}>
              <div>
                <Passengers fill="#0B78C1" />
                {passengerExpression}
              </div>
              {isRailcardApplied && (
                <div className={styles.options}>
                  <Railcard />
                  {railcardsSelectedName}
                </div>
              )}
              {isGroupSaveApplied && (
                <div>
                  <GroupSaveIcon /> Group Save
                </div>
              )}
            </div>
          </div>
          {/* What if we have 2 singles? */}
          <div className={classNames(styles.box)}>
            <div className={styles.fareInfo}>
              <b>{pickedOutboundPrice?.fareType}</b>
              <div
                onClick={() =>
                  setExtraFareSecVisibleOut(!extraFareSecVisibleOut)
                }
              >
                View details &#x3e;
              </div>
              <div className={styles.fareTotal}>
                £{pickedOutboundPrice?.totalAmount}
              </div>
            </div>
            <Collapsible trigger={""} open={extraFareSecVisibleOut}>
              <div className={styles.expanderWrapper}>
                <p> {parseBookedSeats(data?.outwardJourney)}</p>
                <FaresInformation price={pickedOutboundPrice} />
              </div>
            </Collapsible>
          </div>

          {+pickedReturnPrice?.totalAmount > 0 && (
            <div className={classNames(styles.box)}>
              <div className={styles.fareInfo}>
                <b>{pickedReturnPrice?.fareType}</b>
                <div
                  onClick={() =>
                    setExtraFareSecVisibleRet(!extraFareSecVisibleRet)
                  }
                >
                  View details &#x3e;
                </div>
                <div className={styles.fareTotal}>
                  £{pickedReturnPrice?.totalAmount}
                </div>
              </div>
              <Collapsible trigger={""} open={extraFareSecVisibleRet}>
                <div className={styles.expanderWrapper}>
                  <p> {parseBookedSeats(data?.returnJourney)}</p>
                  <FaresInformation price={pickedReturnPrice} />
                </div>
              </Collapsible>
            </div>
          )}
        </div>
        <div className={styles.total}>TOTAL: £{data?.totalPrice}</div>
      </div>
    </div>
  );
};

export default PurchaseSummary;
