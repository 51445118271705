import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./reducers/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";

export const history = createBrowserHistory();

export default function configureStore() {
  return createStore(
    createRootReducer(history),
    composeWithDevTools(
      applyMiddleware(thunk),
      applyMiddleware(routerMiddleware(history))
    )
  );
}
