import React from "react";

type Props = {
  className?: string;
};

function Info({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      className={className}
    >
      <path
        fill="#1e1e50"
        d="M18 36A18 18 0 100 18a18 18 0 0018 18zm-2.812-12.375h1.688v-4.5H13.5V15.75h6.75v7.875h2.25V27h-9v-3.375zM20.25 13.5h-4.5V9h4.5z"
      ></path>
    </svg>
  );
}

export default React.memo(Info);
