import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import App from "./components/App/App";
import configureStore, { history } from "./store";
import "./styles/index.scss";
import "./i18n";
// Screens
import JourneyResults from "./components/Screens/JourneyResults/JourneyResults";
import JourneyPlanner from "./components/Screens/JourneyPlanner/JourneyPlanner";
import PurchaseSummary from "./components/Screens/PurchaseSummary/PurchaseSummary";
import Home from "./components/Screens/Home/Home";
import NoService from "./components/Screens/NoService/NoService";

//handle global exceptions
import { ErrorBoundary } from "react-error-boundary";
const store = configureStore();
const currentPath = store.getState().router.location.pathname;

Sentry.init({
  dsn: "https://e16c862d796a4af592297923cc6c219d@sentry.kadfire.com/10",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App path={currentPath}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={() => <Home />} />
            <Route
              exact
              path="/journey-planner"
              component={() => <JourneyPlanner />}
            />
            <Route
              exact
              path="/journey-results"
              component={() => <JourneyResults />}
            />
            <Route
              exact
              path="/purchase-summary"
              component={() => <PurchaseSummary />}
            />
            <Route exact path="/no-service" component={() => <NoService />} />
          </Switch>
        </ConnectedRouter>
      </App>
    </ErrorBoundary>
  </Provider>
  // </React.StrictMode>
);
