import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { selectPassenger } from "../../selectors/journeyPlanner";
import styles from "./PassengerPicker.module.scss";

import Adult from "../../assets/adult.svg?react";
import ReduceEnable from "../../assets/reduceEnable.svg?react";
import ReduceDisable from "../../assets/reduceDisable.svg?react";
import IncreaseEnable from "../../assets/IncreaseEnable.svg?react";
import IncreaseDisable from "../../assets/IncreaseDisable.svg?react";
import { Passengers } from "../../types/journeyPlanner";
import { RailCardPickerRef } from "../RailcardSelector/RailcardSelector";
import { Text } from "../../components/StyledTags/Text";

const MAX_PASSENGER_ALLOWED = 9;

export interface PassengerPickerRef {
  getPassengers: () => Passengers;
  getTotalCount: () => number;
  resetPrevState: () => void;
}

interface PassengerPickerProps {
  forwardedRef?: React.RefObject<RailCardPickerRef | null>;
}

const PassengerPicker = (
  { forwardedRef }: PassengerPickerProps, // Remove the 'ref' parameter from here
  ref: React.Ref<PassengerPickerRef> // Define the ref type here
) => {
  const { adult, child } = useSelector(selectPassenger);

  const [adultCount, setAdultCount] = useState(adult);
  const [childCount, setChildCount] = useState(child);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && forwardedRef?.current) {
      forwardedRef.current?.updateTotalPossibleRailcards(
        adultCount + childCount
      );
    }
    return () => {
      isMounted = false;
    };
  }, [adultCount, childCount, forwardedRef]);

  useImperativeHandle(ref, () => ({
    getPassengers: (): Passengers => {
      const passenger: Passengers = {
        adult: adultCount,
        child: childCount,
      };
      return passenger;
    },
    getTotalCount: (): number => {
      return adultCount + childCount;
    },
    resetPrevState: (): void => {
      setAdultCount(adult);
      setChildCount(child);
    },
  }));

  const handleReduceAdultCount = useCallback(() => {
    setAdultCount(adultCount - 1);
  }, [adultCount]);

  const handleIncreaseAdultCount = useCallback(() => {
    if (adultCount + childCount < MAX_PASSENGER_ALLOWED) {
      setAdultCount(adultCount + 1);
    }
  }, [adultCount, childCount]);

  const handleReduceChildCount = useCallback(() => {
    setChildCount(childCount - 1);
  }, [childCount]);

  const handleIncreaseChildCount = useCallback(() => {
    if (adultCount + childCount < MAX_PASSENGER_ALLOWED) {
      setChildCount(childCount + 1);
    }
  }, [childCount, adultCount]);

  return (
    <div className={styles.passengerWrapper}>
      <div className={styles.outerContainer}>
        <Adult></Adult>
        <div className={styles.pickerTitle}>Passengers</div>
        <div></div>
        <div className={styles.innerWrapper}>
          <div>
            <div>Adult (16+)</div>
          </div>
          <div className={styles.counter}>
            {adultCount === 0 ? (
              <ReduceDisable />
            ) : (
              <ReduceEnable onClick={handleReduceAdultCount} />
            )}
            <div>{adultCount}</div>
            {adultCount === 9 ? (
              <IncreaseDisable />
            ) : (
              <IncreaseEnable onClick={handleIncreaseAdultCount} />
            )}
          </div>
        </div>
        <div></div>
        <div className={styles.innerWrapper}>
          <div>
            <div>Child (5-15)</div>
            <div className={styles.childInfo}>
              Children under 5 travel for free
            </div>
          </div>
          <div className={styles.counter}>
            {childCount === 0 ? (
              <ReduceDisable />
            ) : (
              <ReduceEnable onClick={handleReduceChildCount} />
            )}
            <div>{childCount}</div>
            {childCount === 9 ? (
              <IncreaseDisable />
            ) : (
              <IncreaseEnable onClick={handleIncreaseChildCount} />
            )}
          </div>
        </div>
        <div></div>
        <div>
          {MAX_PASSENGER_ALLOWED === adultCount + childCount && (
            <Text color="#FF0000">Maximum number of passengers is 9</Text>
          )}
          {0 === adultCount + childCount && (
            <Text color="#FF0000"> Minimum number of passenger is 1</Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(PassengerPicker);
