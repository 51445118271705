import React, { MouseEventHandler } from "react";

function SearchIcon({
  className,
  onClick,
}: Readonly<{
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}>) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 36 36"
      className={className}>
      <g data-name="Group 1196">
        <circle
          cx="18"
          cy="18"
          r="18"
          fill="#0b78c1"
          data-name="Ellipse 111"></circle>
      </g>
      <path
        fill="#fff"
        d="M15.438 7.719a7.7 7.7 0 01-1.484 4.553l4.1 4.108.842.839-1.677 1.681-.839-.839-4.108-4.108a7.72 7.72 0 113.165-6.234zm-7.719 5.344a5.344 5.344 0 10-5.344-5.344 5.344 5.344 0 005.344 5.344z"
        transform="translate(8.325 8.1)"></path>
    </svg>
  );
}

export default React.memo(SearchIcon);
