// Stepper.tsx
import React, { useMemo } from "react";
import styles from "./HeaderStepper.module.scss";
import classNames from "classnames";
import Home from "../../assets/Home";
import { useSelector } from "react-redux";
import { selectCurrentState } from "../../selectors/app";
import { selectJourneyType } from "../../selectors/journeyPlanner";
import { RETURN } from "../../actions/constants";
import { AppGlobalStates } from "types/app";

const HeaderStepper: React.FC = () => {
  const journeyType = useSelector(selectJourneyType);
  const currentState: number = useSelector<AppGlobalStates>(
    selectCurrentState
  ) as number;
  const steps = useMemo(() => {
    if (journeyType === RETURN) {
      return [
        "Home",
        "Journey Details",
        "Outbound",
        "Return",
        "Journey Summary",
      ];
    }
    return ["Home", "Journey Details", "Outbound", "Journey Summary"];
  }, [journeyType]);

  const activeStep = useMemo(() => {
    const stateToStep: { [key: number]: number } = {
      0: 0,
      1: 0,
      2: 1,
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 2,
      8: 3,
      9: journeyType === RETURN ? 4 : 3,
    };
    return stateToStep[currentState];
  }, [currentState, journeyType]);
  const renderStep = (index: number) => {
    if (index === 0) {
      return <Home circleFill="#272764" />;
    } else {
      return (
        <div>
          <span className={styles.stepNumber}>
            <span className={styles.tick} />
          </span>
        </div>
      );
    }
  };
  return (
    <div className={styles.stepper}>
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div
            className={classNames(styles.stepContainer, {
              [styles.lastStep]: index === steps.length - 1,
            })}>
            <div
              className={classNames(styles.step, {
                [styles.active]: index === activeStep,
                [styles.done]: index < activeStep,
                [styles.homeIcon]: index === 0,
              })}>
              {renderStep(index)}
              <span className={styles.stepLabel}>{step}</span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={classNames(styles.connector, {
                  [styles.filled]: index < activeStep,
                })}
              />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default HeaderStepper;
