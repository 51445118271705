import styles from "./Button.module.scss";
import React from "react";

interface ButtonProps {
  data: string;
  type?: number;
  onSelectFunc?: () => void;
  onCloseFunc?: () => void;
}
const Button: React.FC<ButtonProps> = ({
  data,
  type = 1,
  onSelectFunc,
  onCloseFunc,
}) => {
  let btnStyles = styles.select;
  if (type === 2) {
    btnStyles = styles.selectType2;
  }

  return (
    <div className={styles.btnGroup}>
      {onCloseFunc ? (
        <div onClick={onCloseFunc} className={styles.close}>
          Close
        </div>
      ) : (
        <></>
      )}
      {onSelectFunc != null ? (
        <div onClick={onSelectFunc} className={btnStyles}>
          {data}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Button;
