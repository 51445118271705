import React, { useCallback, useState } from "react";

// Styles
import styles from "./JourneyBar.module.scss";
import classNames from "classnames";
// Components
import { useSelector } from "react-redux";
import Arrow from "../../assets/arrow.svg?react";
import FirstClassNotFoundSVG from "../../assets/exclamation.svg?react";
import FastServiceIcon from "../../assets/fastest.svg?react";

import Collapsible from "react-collapsible";
import JourneyButton from "../JourneyButton/JourneyButton";
import { nanoid } from "nanoid";

// import { JourneyInfoModal } from "../JourneyInfoModal/JourneyInfoModal";
import { JourneyInfoModal } from "../JourneyInfoModal/JourneyInfoModal";

import {
  selectCheapestFare,
  selectCheapestFareAmount,
  selectIsETicketAvailable,
  selectPickedOutwardPrice,
} from "../../selectors/journeyResults";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import FaresInfoModal from "../FareInfoModal/FareInfoModal";
import { selectStationByCode } from "../../selectors/stations";
import { LegSolution, Price } from "../../types/journeyResults";
import {
  parseDuration,
  parseHourTimeFromUTC,
} from "../../utils/journeyplanner";
import { selectCurrentState } from "../../selectors/app";
import { AppGlobalStates } from "../../types/app";
import { partition } from "lodash-es";

interface JourneyBarProps {
  legSolution: LegSolution;
  index: number;
  fastestService?: boolean;
}

const JourneyBar: React.FC<JourneyBarProps> = ({
  legSolution,
  index,
  fastestService,
}): React.ReactNode => {
  const [fareTabIndex, setFareTabIndex] = useState<number>(0);
  const {
    id,
    origin,
    destination,
    prices,
    isCancelled,
    arrivalTime,
    travelSegments,
    departureTime,
    overtaken,
    duration,
    changes,
  } = legSolution;
  const { name: depStationName } = useSelector(selectStationByCode(origin));
  const { name: arrStationName } = useSelector(
    selectStationByCode(destination)
  );

  const currentState = useSelector(selectCurrentState);
  const delayed = departureTime?.delayed || arrivalTime?.delayed;

  const departureTimeAdjusted: string = parseHourTimeFromUTC(
    departureTime?.adjustedTime
  );
  const departureTimeScheduled: string = parseHourTimeFromUTC(
    departureTime?.scheduledTime
  );
  const arrivalTimeAdjusted: string = parseHourTimeFromUTC(
    arrivalTime?.adjustedTime
  );
  const arrivalTimeScheduled: string = parseHourTimeFromUTC(
    arrivalTime?.scheduledTime
  );

  const [firstClassFare, standardClassFare]: [Price[], Price[]] = partition(
    prices,
    "firstClass"
  );

  const [isJourneyClick, setJourneyClick] = useState<boolean>(false);
  const cheapestPrice: Price = useSelector(selectCheapestFare(index));
  const eTicketCollection: boolean = useSelector(
    selectIsETicketAvailable(index)
  );
  const durationExpression = parseDuration(duration);

  const cheapestFareAmount = useSelector(selectCheapestFareAmount);

  const pickedOutwardPrice: Price = useSelector(selectPickedOutwardPrice);
  // Either single or return Fares
  // If user has select Return Fare from outbound journey list .
  // then do now show the collapse window in return journey list page

  const [extraFareSecVisible, setExtraFareSecVisible] = useState(false);

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      setFareTabIndex(tabIndex);
    },
    [setFareTabIndex]
  );

  const handleCollapseExpandWindow = useCallback(() => {
    setExtraFareSecVisible(!extraFareSecVisible);
  }, [extraFareSecVisible]);

  return (
    <div
      className={classNames(styles.resultOuterWrap, {
        [styles.disableText]: isCancelled || !eTicketCollection,
      })}
    >
      <div className={styles.journeyDetails}>
        <div
          className={classNames(styles.leftSectionWrap, {
            [styles.highlightRed]: isCancelled,
            [styles.strike]: isCancelled,
          })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "23px",
            }}
          >
            <div
              className={classNames(styles.station, styles.withArrow)}
              onClick={() => {
                setJourneyClick(true);
                console.log(departureTime);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div className={styles.time}>
                  <span className={classNames({ [styles.strike]: delayed })}>
                    {departureTimeScheduled}
                  </span>
                  {delayed && (
                    <span className={styles.highlightRed}>
                      {departureTimeAdjusted}
                    </span>
                  )}
                </div>

                <Arrow className={styles.arrowSpacing} />
              </div>
              <div className={styles.details}>
                <div>{depStationName}</div>
              </div>
              {(!eTicketCollection || overtaken || isCancelled) && (
                <div className={styles.msgSections}>
                  {!eTicketCollection && (
                    <div className={styles.journeyMsgs}>
                      Unavailable as eTicket
                    </div>
                  )}
                  {overtaken && (
                    <div
                      className={classNames([
                        styles.highlightRed,
                        styles.journeyMsgs,
                      ])}
                    >
                      This Train is overtaken by a later service
                    </div>
                  )}
                  {isCancelled && (
                    <div
                      className={classNames([
                        styles.highlightRed,
                        styles.journeyMsgs,
                      ])}
                    >
                      Cancelled
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={styles.duration}>
              {fastestService && <FastServiceIcon />}
              <span
                className={classNames({
                  [styles.quickService]: fastestService,
                })}
              >
                {`${durationExpression} `}
              </span>
              {`, ${
                changes > 0
                  ? `${changes} ${changes > 1 ? "changes" : "change"}`
                  : "direct"
              } `}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "23px",
            }}
          >
            <div className={styles.station}>
              <div className={styles.time}>
                <span className={classNames({ [styles.strike]: delayed })}>
                  {arrivalTimeScheduled}
                </span>
                {delayed && (
                  <span className={classNames([styles.highlightRed])}>
                    {arrivalTimeAdjusted}
                  </span>
                )}
              </div>
              <div className={styles.details}>
                <div>{arrStationName}</div>
              </div>
            </div>
            <JourneyInfoModal journeyId={id} travelSegments={travelSegments} />
          </div>
        </div>
        <JourneyButton
          isClicked={isJourneyClick}
          legSolutionIndex={index}
          isCheapest={cheapestFareAmount === cheapestPrice?.totalAmount}
          price={cheapestPrice}
          fareInfoComponent={<FaresInfoModal price={cheapestPrice} />}
          moreFareComponent={
            (!pickedOutwardPrice?.isReturn ||
              currentState === AppGlobalStates.OutboundJourneysResult) && (
              <div
                className={styles.otherFaresButton}
                onClick={handleCollapseExpandWindow}
              >
                Other fares &#62;
              </div>
            )
          }
        />
      </div>

      <Collapsible trigger={""} open={extraFareSecVisible}>
        <div className={styles.expanderWrapper}>
          <Tabs
            className={styles.tabsWrapper}
            selectedIndex={fareTabIndex}
            onSelect={handleTabChange}
          >
            <TabList className={styles.tabList}>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>
                <span>Standard</span>
              </Tab>
              <Tab className={styles.tab} selectedClassName={styles.activeTab}>
                <span>First Class</span>
              </Tab>
            </TabList>

            <TabPanel className={styles.tabPanel}>
              <div className={classNames("Standard", styles.fareTypeContent)}>
                {Object.entries(standardClassFare).map(
                  ([, otherFares], index) => {
                    return (
                      <div
                        key={otherFares.fareId}
                        className={classNames(styles.otherFareSection, {
                          [styles.disableText]: !otherFares?.eticketCollection,
                        })}
                      >
                        <div className={styles.fareType}>
                          {otherFares?.fareType}
                        </div>
                        <div className={styles.flexiContainer}>
                          <JourneyButton
                            legSolutionIndex={index}
                            isCheapest={
                              cheapestFareAmount === otherFares?.totalAmount
                            }
                            price={otherFares}
                            fareInfoComponent={
                              <FaresInfoModal
                                price={otherFares}
                                isCollapsed={true}
                              />
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div
                className={classNames("First Class", styles.fareTypeContent)}
              >
                {firstClassFare.length === 0 ? (
                  <div className={styles.firstClassNotFound}>
                    <FirstClassNotFoundSVG />
                    <p>
                      There are no First Class tickets available for this
                      service
                    </p>
                  </div>
                ) : (
                  Object.entries(firstClassFare).map(
                    ([, otherFares], index) => {
                      return (
                        <div
                          key={nanoid()}
                          className={classNames(styles.otherFareSection, {
                            [styles.disableText]:
                              !otherFares?.eticketCollection,
                          })}
                        >
                          <div>{otherFares?.fareType}</div>
                          <div className={styles.flexiContainer}>
                            <JourneyButton
                              legSolutionIndex={index}
                              isCheapest={
                                cheapestFareAmount === otherFares?.totalAmount
                              }
                              price={otherFares}
                              fareInfoComponent={
                                <FaresInfoModal
                                  price={otherFares}
                                  isCollapsed={true}
                                />
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                  )
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Collapsible>
    </div>
  );
};

export default JourneyBar;
