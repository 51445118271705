import { createSelector } from 'reselect'

const adultRailcards = [
  'UK_ANNUAL_GOLD',
  'UK_DISABILITY',
  'UK_HM_ARMED_FORCES',
  'UK_JOBCENTRE_PLUS',
  'UK_NETWORK',
  'UK_SCOTTISH_YOUTH',
  'UK_SENIOR',
  'UK_YOUNG_ADULT',
  'UK_YOUTH',
  'UK_YOUTH_16_17',
  'UK_VETERAN'
]

const childRailcards = [
  'UK_ANNUAL_GOLD',
  'UK_CHILD_DISABILITY',
  'UK_HM_ARMED_FORCES',
  'UK_NETWORK',
  'UK_VETERAN'
]

export const selectRailcardList = (state) => state.railcards.list || []

export const selectRailcardByPassengerType = (type) => createSelector(
  selectRailcardList,
  (railcards) => railcards.filter(railcard => type === 'adult' ? adultRailcards.includes(railcard.program) : childRailcards.includes(railcard.program))
)

export const selectRailcardByDescription = (description) => (state) => state.railcards.list.find(
  railcard => railcard.description === description
) || []
