import { createSelector } from "reselect";

export const selectStations = (state) => state.stations.list || [];

export const selectMainStations = createSelector(selectStations, (stations) =>
  stations.filter((item) => item.crs)
);

export const selectStationByCode = (code) =>
  createSelector(selectStations, (stations) =>
    stations.find((item) => item.nlc === code)
  );

export const selectPopularStations = (state) =>
  state.stations.popular.slice(0, 14) || [];
//UI shows top 14 stations
