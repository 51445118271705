import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Styles
import styles from "./App.module.scss";

//Assets
import TopNotchSVG from "../../assets/TopNotch";
import NotchSVG from "../../assets/nav-notch.svg?react";
import Accessibility from "../../assets/Acessibility";
import Language from "../../assets/Language";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Home from "../../assets/Home";
import Back from "../../assets/Back";

// Components
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Clock from "../../components/Clock/Clock";
// Actions
import {
  AppDispatch,
  appInitialize,
  appOpenHome,
  appSetCurrentState,
} from "../../actions/app";
import { setResetJourneyResults } from "../../actions/journeyResults";

//Selectors
import { selectJourneyType } from "../../selectors/journeyPlanner";
import { selectCurrentState, selectIsHome } from "../../selectors/app";

//Types
import { JourneyType } from "../../types/journeyPlanner";
import { AppGlobalStates } from "../../types/app";
import { useTranslation } from "react-i18next";

//Others
import { history } from "../../store";
import packageInfo from "../../../package.json";

const App: React.FC<{ children: React.ReactNode }> = ({
  children,
}): React.ReactNode => {
  const dispatch = useDispatch<AppDispatch>();
  const isHome: boolean = useSelector(selectIsHome);
  const journeyType = useSelector(selectJourneyType);
  const currentState = useSelector(selectCurrentState);

  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [, setCurrentLanguage] = useState(language);

  useEffect(() => {
    //dispatch(appGetToken()).then(() => dispatch(appInitialize))
    dispatch(appInitialize());
  }, [dispatch]);

  useEffect(() => {
    const clearListener = history.listen(
      (location: Location, action: string) => {
        if (action === "POP") {
          if (location?.pathname === "/journey-planner") {
            dispatch(setResetJourneyResults());
          }
          if (location?.pathname === "/journey-results") {
            if (
              journeyType === JourneyType.RETURN &&
              currentState !== AppGlobalStates.ReturnJourneysResult
            ) {
              dispatch(
                appSetCurrentState(AppGlobalStates.ReturnJourneysResult)
              );
            } else {
              dispatch(
                appSetCurrentState(AppGlobalStates.OutboundJourneysResult)
              );
            }
          }
        }
      }
    );

    return () => {
      clearListener();
    };
  });

  const handleColors = (attribute: string) => {
    const metaTag = document.querySelector("html");
    if (metaTag) {
      metaTag.setAttribute("hc", attribute); // Change the color to red
    }
  };

  const handleLanguage = (language: string) => {
    setCurrentLanguage(language);
    changeLanguage(language);
  };
  return (
    <React.Fragment>
      <div className={styles.clockContainer}>
        <TopNotchSVG />
        <div className={styles.stackTop}>
          <Clock />
        </div>
      </div>

      <div className={styles.notchWrapper}>
        <TopNotchSVG className={styles.boxInvert} />
        <div
          style={{
            display: "flex",
            position: "relative",
            left: "20px",
            gap: "12px",
          }}
        >
          <Menu menuButton={<Accessibility />}>
            <MenuItem onClick={() => handleColors("a")}>Original</MenuItem>
            <MenuItem onClick={() => handleColors("a1")}>
              High Contrast
            </MenuItem>
            <MenuItem onClick={() => handleColors("a3")}>Invert Color</MenuItem>
            <MenuItem onClick={() => handleColors("a2")}>Gray Scale</MenuItem>
          </Menu>
          <Menu menuButton={<Language />}>
            <MenuItem onClick={() => handleLanguage("en")}>English</MenuItem>
            <MenuItem onClick={() => handleLanguage("pt")}>Portuguese</MenuItem>
          </Menu>

          <div
            onClick={() => window.location.reload()}
            className={styles.version}
          >{`${packageInfo.version}`}</div>
        </div>
      </div>

      <div className={styles.frame}>
        <Header />
        <div className={styles.innerFrameWrapper}>
          {!isHome ? (
            <div className={styles.navigationContainer}>
              <NotchSVG className={styles.notch} />
              <div className={styles.navigationButtons}>
                <Home onClick={() => dispatch(appOpenHome())} />
                <Back onClick={() => history.goBack()} />
              </div>
            </div>
          ) : null}
          <div className={styles.innerFrame}>{children}</div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default App;
