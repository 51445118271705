import axios from "axios";
import {
  selectArrivalStation,
  selectDepartureStation,
  selectJourneyType,
} from "../../../selectors/journeyPlanner";
import {
  selectPickedOutwardBookingLeg,
  selectPickedOutwardPrice,
  selectPickedReturnBookingLeg,
  selectPickedReturnPrice,
} from "../../../selectors/journeyResults";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { RETURN } from "../../../actions/constants";
import { Booking, BookingStatus } from "../../../types/bookings";
import { Station } from "../../../types/journeyPlanner";
import { timeout } from "../../../utils/purchaseSummary";
import axiosHttp from "../../../actions/axios";

export const useBookingAPI = (): APIStates => {
  const selectedOutwardJourney = useSelector(selectPickedOutwardPrice);
  const selectedReturnJourney = useSelector(selectPickedReturnPrice);

  const pickedOutwardBookingLeg = useSelector(selectPickedOutwardBookingLeg);
  const pickedReturnBookingLeg = useSelector(selectPickedReturnBookingLeg);

  const departureStation: Station = useSelector(selectDepartureStation);
  const arrivalStation = useSelector(selectArrivalStation);

  const journeyType = useSelector(selectJourneyType);

  const [data, setData] = useState<Booking | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("useBookingAPI hooks init");
    const getBookingData = async () => {
      try {
        const response = await axiosHttp.post(`/bookings`, {
          origin: departureStation?.nlc,
          destination: arrivalStation?.nlc,
          appVersion: "1.0.0",
          deviceType: "WEB",
          outwardSingleID: selectedOutwardJourney?.fareId,
          outwardJourney: JSON.stringify(pickedOutwardBookingLeg?.bookingLegs),
          toc: selectedOutwardJourney?.toc,
          ...(journeyType === RETURN && {
            returnSingleID: selectedReturnJourney?.fareId,
            returnToc: selectedReturnJourney?.toc,
            returnJourney: JSON.stringify(pickedReturnBookingLeg?.bookingLegs),
          }),
        });
        setData(response?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (data === null) {
      getBookingData();
    }
  }, [
    arrivalStation?.nlc,
    data,
    departureStation?.nlc,
    journeyType,
    pickedOutwardBookingLeg?.bookingLegs,
    pickedReturnBookingLeg?.bookingLegs,
    selectedOutwardJourney?.fareId,
    selectedOutwardJourney?.toc,
    selectedReturnJourney?.fareId,
    selectedReturnJourney?.toc,
  ]);

  return { data, loading, error };
};

export const useLockAndListenBookingAPI = (data: Booking | null): boolean => {
  const [isOrderUnLocked, setOrderUnLock] = useState(false);
  const [isOrderLock, setOrderLock] = useState(false);

  const componentMountStatus = useRef(true);

  useEffect(() => {
    // const axiosConfig = {
    //   maxBodyLength: Infinity,
    //   headers: { Authorization: token },
    // };

    const patchLockAPI = async () => {
      let response = null;
      try {
        response = await axiosHttp.patch(
          `/bookings/${data?.id}?deviceToken=${data?.deviceToken}&lock=null`,
          null
        );
      } catch (error) {
        console.log("Failing to lock the order. Trying again...", error);
      } finally {
        if (response?.status !== 204) {
          await timeout(Math.random() * 2000);
          patchLockAPI();
        } else {
          componentMountStatus.current && getLockStatusAPI();
          setOrderLock(true);
        }
      }
    };

    const getLockStatusAPI = async () => {
      let response = null;
      try {
        response = await axiosHttp.get(
          `/bookings/${data?.id}?deviceToken=${data?.deviceToken}`
          // axiosConfig
        );
      } catch (error) {
        console.log("Failing to get Lock status. Trying again...", error);
      } finally {
        if (response?.data?.state === BookingStatus.Incomplete) {
          setOrderUnLock(true);
        } else {
          await timeout(Math.random() * 3000);
          componentMountStatus.current && getLockStatusAPI();
        }
      }
    };

    if (data !== null && !isOrderLock) {
      patchLockAPI();
    }
  }, [data, componentMountStatus, isOrderLock, isOrderUnLocked]);

  useEffect(() => {
    componentMountStatus.current = true;
    return () => {
      componentMountStatus.current = false;
    };
  }, []);

  return isOrderUnLocked;
};
