import React from "react";

import styles from "./FareInformation.module.scss";

import { useSelector } from "react-redux";

import {
  selectAlternativeDestination,
  selectAlternativeOrigins,
  selectGroupTicketsWithRailcardInfo,
} from "../../selectors/journeyResults";
import { Price, fareInfo } from "../../types/journeyResults";

import { Station } from "../../types/journeyPlanner";
import dayjs from "dayjs";

interface FareInformationProps {
  price: Price;
}

const FaresInformation: React.FC<FareInformationProps> = ({
  price,
}): React.ReactNode => {
  const outboundValidDate = dayjs(price?.outwardValidity?.until).format(
    "dddd DD MMMM YYYY"
  );

  const fareInformation: fareInfo[] = useSelector(
    selectGroupTicketsWithRailcardInfo(price?.tickets)
  );
  const returnValidDate = dayjs(price?.returnValidity?.until).format(
    "dddd DD MMMM YYYY"
  );
  const outwardJourneyBreakStatus = `You ${
    price?.outwardValidity?.break ? "can" : "cannot"
  } break and resume your journey`;
  const returnJourneyBreakStatus = `You ${
    price?.returnValidity?.break ? "can" : "cannot"
  } break and resume your journey`;

  const alternativeDestinations: Station[] = useSelector(
    selectAlternativeDestination(price)
  );

  const alternativeOrigins: Station[] = useSelector(
    selectAlternativeOrigins(price)
  );

  return (
    <>
      <div>{price?.routeDescription}</div>
      <br />
      {fareInformation?.map((ticket: fareInfo) => {
        return (
          <React.Fragment key={price.fareId}>
            <b>{ticket?.railcard}</b>

            {ticket.adult > 0 && (
              <li key="adult">{`${
                ticket.adult
              } x Adult : £ ${(+ticket.adultPrice).toFixed(2)}`}</li>
            )}
            {ticket.children > 0 && (
              <li key="children">{`${
                ticket.children
              } x Child : £ ${(+ticket.childrenPrice).toFixed(2)}`}</li>
            )}
          </React.Fragment>
        );
      })}

      <br />
      <b>Valid dates</b>
      <div>Outbound : {outboundValidDate} </div>
      {price?.returnValidity != null && <div>Return : {returnValidDate} </div>}
      <br />
      <b>Break of journey</b>
      <div>
        {`Outward : `} {outwardJourneyBreakStatus}
      </div>
      {price?.returnValidity != null && (
        <div>
          {`Return : `} {returnJourneyBreakStatus}
        </div>
      )}
      {alternativeDestinations.length > 0 && (
        <>
          <b>Alternative Destination</b>
          {
            <div>
              {alternativeDestinations.map((altDest) => (
                <div key={altDest.nlc}>{altDest.name}</div>
              ))}
            </div>
          }
        </>
      )}
      {alternativeOrigins.length > 0 && (
        <>
          <b>Alternative Origins</b>
          {
            <div>
              {alternativeOrigins.map((altDest) => (
                <div key={altDest.nlc}>{altDest.name}</div>
              ))}
            </div>
          }
        </>
      )}
      <p className={styles.routeName}>{price?.routeName}</p>
    </>
  );
};

export default FaresInformation;
