import React from "react";

function OpenReturn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="#fff"
        data-name="Ellipse 176"
      ></circle>
      <g data-name="Group 2737" transform="translate(9 9.976)">
        <path
          fill="#1e1e50"
          d="M3.649.932L.806 3.774 0 4.581l1.609 1.608.8-.8.9-.9V15.95h2.28V4.484l.9.9.806.806L8.9 4.581l-.8-.8L5.259.932l-.806-.8z"
          data-name="Path 1394"
          transform="translate(0 -.032)"
        ></path>
        <path
          fill="#0b78c1"
          d="M15.447 0v11.465l-.9-.9-.8-.8-1.61 1.609.8.8 2.843 2.842.8.806.8-.806 2.843-2.842.806-.806-1.61-1.609-.8.8-.9.9V0z"
          data-name="Path 1395"
          transform="translate(-3.034)"
        ></path>
      </g>
    </svg>
  );
}

export default React.memo(OpenReturn);
