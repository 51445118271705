import React from "react";

type Props = {
  className?: string;
  fill?: string;
};

function Caret({ className, fill }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="27.138"
      viewBox="0 0 16 27.138"
      className={className}
    >
      <path
        fill={fill || "#0B78C1"}
        d="M13.569 0L0 13.569 2.431 16 13.569 4.806l11.138 11.138 2.431-2.431z"
        transform="rotate(90 8 8)"
      ></path>
    </svg>
  );
}

export default React.memo(Caret);
