import React, { HTMLAttributes, ReactNode } from "react";

interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  gap?: string;
  justifyItems?: AlignSetting;
  justifyContent?: AlignSetting;
  background?: string;
  padding?: string;
  align?: string;
  margin?: string;
}

export const Grid: React.FC<WrapperProps> = ({
  children,
  gap,
  justifyItems,
  margin,
  padding,
  align,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    display: "grid",
    gridGap: gap ?? "10px",
    justifyItems,
    margin,
    padding,
    alignContent: "str",
    alignItems: align,
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};

export const Flex: React.FC<WrapperProps> = ({
  children,
  gap,
  justifyContent,
  align,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    display: "flex",
    gap: gap ?? "10px",
    justifyContent,
    alignItems: align ?? "center",
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};

export const ModalWrap: React.FC<WrapperProps> = ({
  children,
  padding,
  justifyContent,
  ...restProps
}) => {
  const styles: React.CSSProperties = {
    display: "grid",
    background: "#ffffff",
    boxShadow: "0px 5px 10px #00000031",
    borderRadius: "22px",
    padding: padding ?? "80px",
    justifyContent,
  };
  return (
    <div style={styles} {...restProps}>
      {children}
    </div>
  );
};
