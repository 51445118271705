import React from "react";

type Props = {
  className?: string;
  circleFill?: string;
  homeFill?: string;
  onClick?: () => void;
};

function Home({ onClick, circleFill, homeFill, className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 36 36"
      style={{ backgroundColor: circleFill }}
      onClick={onClick}
      className={className}
    >
      <g data-name="Group 2398" transform="rotate(180 18.072 18)">
        <path
          fill={circleFill || "#0b78c1"}
          d="M18 36A18 18 0 100 18a18 18 0 0018 18z"
          data-name="Path 1283"
          transform="translate(.143)"
        ></path>
        <path
          fill={homeFill || "white"}
          d="M17.993 7.889h2.257v1.674L10.139 18 0 9.563V7.889h2.254V0h5.621v5.625h4.5V0h5.653z"
          transform="translate(7.893 10)"
        ></path>
      </g>
    </svg>
  );
}

export default React.memo(Home);
