import { useState, useCallback, useRef, useEffect, memo } from "react";

import Keyboard from "react-simple-keyboard";
import Modal from "../../Modal/Modal";

// Styles
import styles from "./StationPickerModal.module.scss";
import "./Keyboard.css";

import { getSuggestedStations } from "../../../TrieSearch";

import Button from "../../../components/Button/Button";
import SearchInput from "../../../components/SearchInput/SearchInput";
import Caret from "../../../assets/Caret";

const keyboardLayout = {
  default: [
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "Z X C V B N M",
    "{space} {bksp}",
  ],
};

type StationPickerProps = {
  onStationSelect: () => void;
  openState: boolean;
  onModalClose: () => void;
};

function StationPickerModal({
  onStationSelect,
  openState,
  onModalClose,
}: StationPickerProps) {
  const keyboard = useRef();
  const [inputVal, setInputVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleKeypadPress = useCallback((keypadText) => {
    setSearchResults(getSuggestedStations(keypadText.toLowerCase()));
    setInputVal(keypadText.toLowerCase());
  }, []);

  useEffect(() => {
    keyboard.current.setInput(inputVal);
  }, [inputVal]);

  const handleBackspace = useCallback(() => {
    setSearchResults(getSuggestedStations(inputVal.slice(0, -1)));
    setInputVal(inputVal.slice(0, -1));
  }, [inputVal]);

  const handleClearSearchText = useCallback(() => {
    setInputVal("");
    setSearchResults(getSuggestedStations(""));
    keyboard.current.setInput("");
  }, []);

  const handleStationPickerModalClose = useCallback(() => {
    onModalClose();
    handleClearSearchText();
  }, [handleClearSearchText, onModalClose]);

  const handleStationSelect = useCallback(
    (selectedStation) => {
      handleStationPickerModalClose();
      onStationSelect(selectedStation);
      handleClearSearchText();
    },
    [handleClearSearchText, handleStationPickerModalClose, onStationSelect]
  );

  const highlightFirstOccurrence = useCallback((text, keyword, crsCode) => {
    const index = text.toLowerCase().indexOf(keyword.toLowerCase());

    let highlightedText = text;
    if (index !== -1) {
      const before = text.substring(0, index);
      const highlighted = text.substring(index, index + keyword.length);
      const after = text.substring(index + keyword.length);

      highlightedText = (
        <>
          {before}
          <strong>{highlighted}</strong>
          {after}
        </>
      );
    }
    return (
      <>
        {highlightedText}
        <span style={{ color: "#0B78C1" }}>{` ${crsCode}`}</span>
      </>
    );
  }, []);

  return (
    <Modal open={openState}>
      <div></div>
      <div className={styles.outerWrapper}>
        <div className={styles.searchInputWrap}>
          <div className={styles.stationSearchEditBoxWrapper}>
            <SearchInput
              placeHolder="Search"
              value={inputVal}
              onClearClicked={handleClearSearchText}
            />
          </div>
        </div>

        <div className={styles.searchResults}>
          {searchResults.map((station, index) => (
            <div
              key={`${station}-${index}`}
              className={styles.station_Names}
              onClick={() => handleStationSelect(station)}
            >
              <p className={styles.textEllipse}>
                {highlightFirstOccurrence(
                  station?.name,
                  inputVal,
                  station?.crs
                )}
              </p>
              <Caret />
            </div>
          ))}
        </div>

        <Keyboard
          layout={keyboardLayout}
          theme="hg-theme-default keyboard"
          display={{
            "{bksp}": "⌫",
            "{space}": "SPACE",
          }}
          onKeyPress={(button) => {
            button === "{bksp}" && handleBackspace();
          }}
          keyboardRef={(r) => (keyboard.current = r)}
          onChange={handleKeypadPress}
        />
      </div>

      <Button onCloseFunc={handleStationPickerModalClose} />
    </Modal>
  );
}

export default memo(StationPickerModal);
