import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./Calendar.module.scss";
import "./Calendar.css";
import dayjs from "dayjs";
import { CaptionProps, DayPicker, useNavigation } from "react-day-picker";
import PrevMonthIcon from "../../assets/prev-month.svg?react";
import NextMonthIcon from "../../assets/next-month.svg?react";
import { format } from "date-fns";
import { toknDate } from "../../utils/journeyplanner";
import { ToknDate, ToknUTC } from "../../types/journeyPlanner";
import { TimePickerRef } from "../TimePicker/TimePicker";

interface ToknCalendarProps {
  date: ToknUTC;
  timeRef: React.RefObject<TimePickerRef | null>;
  startDate: ToknUTC;
}

export interface ToknCalendarRef {
  getToknDate: () => ToknDate;
}

const ToknCalendar = (
  { date, timeRef, startDate }: ToknCalendarProps, // Remove the 'ref' parameter from here
  ref: React.Ref<ToknCalendarRef> // Define the ref type here
) => {
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(
    new Date(date)
  );

  useEffect(() => {
    let dayJs = dayjs(selectedDay);
    const currentDate = dayjs(startDate);
    const isToday = currentDate.isSame(dayJs, "day"); // Check if it's the same day
    if (timeRef?.current) {
      timeRef?.current?.setIsToday(isToday, selectedDay);
    }
  }, [selectedDay, startDate, timeRef]);

  useImperativeHandle(ref, () => ({
    getToknDate: (): ToknDate => {
      let dayJs = dayjs(selectedDay);
      return toknDate(dayJs);
    },
  }));

  function CustomCaption(props: CaptionProps) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <div className={styles.calendarHeader}>
        <PrevMonthIcon
          onClick={() => previousMonth && goToMonth(previousMonth)}
        />

        <div className={styles.headerMidSection}>
          <div style={{ fontSize: "24px" }}>
            {format(props.displayMonth, "MMMM")}
          </div>
          <div
            style={{
              fontSize: "16px",
              margin: "26px 0px 24px 0px",
              textAlign: "center",
            }}
          >
            {selectedDay && format(selectedDay, "dd MMM, yyyy")}
          </div>
        </div>
        <NextMonthIcon onClick={() => nextMonth && goToMonth(nextMonth)} />
      </div>
    );
  }
  const futureDate = new Date(
    new Date().getTime() + 12 * 7 * 24 * 60 * 60 * 1000
  );
  return (
    <>
      <DayPicker
        mode="single"
        required
        fromMonth={new Date(startDate)}
        components={{
          Caption: CustomCaption,
        }}
        disabled={{
          before: new Date(startDate),
          after: new Date(futureDate),
        }}
        selected={selectedDay}
        onSelect={setSelectedDay}
        defaultMonth={selectedDay} // Set the initial month
      />
      {/* <div>
        <div>Today</div>
        <div>Tommorrow</div>
      </div> */}
    </>
  );
};

export default forwardRef(ToknCalendar);

//user selects today  10:00am
