import React from "react";
import classNames from "classnames";

// Styles
import styles from "./Modal.module.scss";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  type?: number;
}

const Modal: React.FC<ModalProps> = ({ children, open, type }) => {
  const isModalVisible = open;
  return (
    <>
      <div
        className={classNames(styles.modal, {
          [styles.showModal]: isModalVisible,
        })}
      >
        <div></div>
        <div className={styles.modalContent}>
          <div className={styles.modalInnerWrapper}>{children}</div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Modal;
