import { SegmentInfo, TravelSegment } from "../../types/journeyResults";

export const transformCallingPoints = (
  travelSegments: Array<TravelSegment>,
  segmentInfoArray: Array<SegmentInfo>
): SegmentInfo[][] => {
  segmentInfoArray = segmentInfoArray.filter(
    (item) => item.pattern !== "Passing"
  );

  return travelSegments.map((segment) => {
    const originIndex = segmentInfoArray.findIndex((item: SegmentInfo) => {
      return (
        item?.station === segment?.origin?.nlc &&
        (item?.time?.adjustedTime === segment?.departureDateTime ||
          segment?.type === "Walk")
      );
    });
    const destinationIndex = segmentInfoArray.findIndex(
      (item: SegmentInfo, index: number) => {
        return (
          (index >= originIndex || segment?.type === "Walk") &&
          item?.station === segment?.destination?.nlc
        );
      }
    );

    const callingPoints = segmentInfoArray.slice(
      originIndex,
      destinationIndex + 1
    );
    return callingPoints;
  });
};
