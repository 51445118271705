import { createSelector } from "reselect";
import {
  ArrivalDepart,
  DateTime,
  DateTimePicker,
  JourneyPlannerState,
  JourneyType,
  Passengers,
  Railcards,
  Station,
  ToknDate,
  ToknTime,
  ToknUTC,
  ViaAvoid,
} from "../types/journeyPlanner";
import { selectCurrentState } from "./app";
import { AppGlobalStates } from "../types/app";
import dayjs from "dayjs";

export const selectJourneyPlanner = (state: any): JourneyPlannerState =>
  state.journeyPlanner;

export const selectDepartureStation = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): Station => journeyPlanner.departureStation
);

export const selectArrivalStation = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): Station => journeyPlanner.arrivalStation
);

export const selectTravelViaAvoidStation = createSelector(
  selectJourneyPlanner,
  (journeyPlanner) => journeyPlanner.travelViaOrAvoid.station
);

export const selectEnableTravelViaAvoid = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): boolean => journeyPlanner.travelViaOrAvoid.enabled
);

export const selectViaAvoidOption = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): ViaAvoid => journeyPlanner.travelViaOrAvoid.isViaAvoid
);

export const selectJourneyType = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): JourneyType => journeyPlanner.journeyDetails.journeyType
);

//outbound data time isArrival

export const selectOutbound = createSelector(
  selectJourneyPlanner,
  ({ journeyDetails }): DateTimePicker => journeyDetails.outbound
);

export const selectOutboundDateTime = createSelector(
  selectOutbound,
  ({ dateTime }): DateTime => dateTime
);

export const selectOutboundDateTimeUTC = createSelector(
  selectOutboundDateTime,
  ({ utc }): ToknUTC => utc
);

export const selectOutboundStartUTC = createSelector(
  selectOutbound,
  ({ startUTC }): ToknUTC => startUTC
);
export const selectOutboundEndUTC = createSelector(
  selectOutbound,
  ({ endUTC }): ToknUTC => endUTC
);

export const selectOutboundDate = createSelector(
  selectOutboundDateTime,
  ({ date }): ToknDate => date
);

export const selectOutboundTime = createSelector(
  selectOutboundDateTime,
  ({ time }): ToknTime => time
);

export const selectOutboundIsArrival = createSelector(
  selectOutbound,
  ({ isArrival }): ArrivalDepart => isArrival
);

export const selectOutboundDateTimeExpression = createSelector(
  selectOutbound,
  selectOutboundIsArrival,
  (outboundData: DateTimePicker, isArrival: ArrivalDepart): string => {
    const { dateTime } = outboundData;
    const text = isArrival === ArrivalDepart.ARRIVAL ? "Arriving" : "Departing";

    return `${dateTime.date.expression} ${text} ${dateTime.time.expression}`;
  }
);
// Return Date time isArrival

export const selectReturn = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): DateTimePicker => journeyPlanner.journeyDetails.return
);

export const selectReturnDateTime = createSelector(
  selectReturn,
  ({ dateTime }): DateTime => dateTime
);

export const selectReturnDateTimeUTC = createSelector(
  selectReturnDateTime,
  ({ utc }): ToknUTC => utc
);

export const selectReturnStartUTC = createSelector(
  selectReturn,
  ({ startUTC }): ToknUTC => startUTC
);

export const selectReturnEndUTC = createSelector(
  selectReturn,
  ({ endUTC }): ToknUTC => endUTC
);

export const selectReturnDate = createSelector(
  selectReturnDateTime,
  ({ date }): ToknDate => date
);

export const selectReturnTime = createSelector(
  selectReturnDateTime,
  ({ time }): ToknTime => time
);
export const selectReturnIsArrival = createSelector(
  selectReturn,
  ({ isArrival }): ArrivalDepart => isArrival
);

export const selectReturnDateTimeExpression = createSelector(
  selectReturn,
  selectReturnIsArrival,
  (returnData: DateTimePicker, isArrival: ArrivalDepart): string => {
    const { dateTime } = returnData;
    const text = isArrival === ArrivalDepart.ARRIVAL ? "Arriving" : "Departing";
    return `${dateTime.date.expression} ${text} ${dateTime.time.expression}`;
  }
);

////Passenger & Railcard options

export const selectPassenger = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): Passengers => journeyPlanner.passenger
);

export const selectPassengerExpression = createSelector(
  selectPassenger,
  ({ adult, child }: Passengers): string => `${
    adult > 0 ? `${adult} x Adult` : ""
  } 
    ${child > 0 && adult > 0 ? " & " : ""}
    ${child > 0 ? ` ${child} x Child ` : ""}`
);

export const selectPassengerCount = createSelector(
  selectPassenger,
  (passenger): number => passenger.adult + passenger.child
);

export const selectRailcard = createSelector(
  selectJourneyPlanner,
  (journeyPlanner): Railcards => journeyPlanner.railcard
);

export const selectRailcardNames = createSelector(
  selectRailcard,
  (railcardList): string => {
    if (railcardList.length > 1) return " Multiple Railcards";
    return railcardList.map((item) => `${item.name}`).join(",");
  }
);

export const selectRailcardCodes = createSelector(
  selectRailcard,
  (railcardList): string[] => {
    const railcardCodes = railcardList.flatMap((railcard) => {
      const { code, count } = railcard;
      return Array(count).fill(`"${code}"`);
    });
    return railcardCodes;
  }
);

export const selectIsRailcardApplied = createSelector(
  selectRailcard,
  (railcardList): boolean => (railcardList.length > 0 ? true : false)
);

export const selectDateTimeExpression = createSelector(
  selectCurrentState,
  selectOutboundDateTimeExpression,
  selectReturnDateTimeExpression,
  (currentState, outboundDateTimeExpression, returnDateTimeExpression) => {
    switch (currentState) {
      case AppGlobalStates.OutboundDateTimePicker:
        return outboundDateTimeExpression;
      case AppGlobalStates.ReturnDateTimePicker:
        return returnDateTimeExpression;
    }
  }
);

export const selectIsArrival = createSelector(
  selectCurrentState,
  selectOutboundIsArrival,
  selectReturnIsArrival,
  (currentState, outboundIsArrival, returnIsArrival) => {
    switch (currentState) {
      case AppGlobalStates.OutboundDateTimePicker:
        return outboundIsArrival;
      case AppGlobalStates.ReturnDateTimePicker:
        return returnIsArrival;
    }
  }
);

export const selectTime = createSelector(
  selectCurrentState,
  selectOutboundTime,
  selectReturnTime,
  (currentState, outboundTime, returnTime): ToknTime => {
    switch (currentState) {
      case AppGlobalStates.OutboundDateTimePicker:
        return outboundTime;
      case AppGlobalStates.ReturnDateTimePicker:
        return returnTime;
    }
  }
);

export const selectDateTimeUTC = createSelector(
  selectCurrentState,
  selectOutboundDateTimeUTC,
  selectReturnDateTimeUTC,
  (currentState, outboundDateTimeUTC, returnDateTimeUTC): ToknUTC => {
    switch (currentState) {
      case AppGlobalStates.OutboundDateTimePicker:
        return outboundDateTimeUTC;
      case AppGlobalStates.ReturnDateTimePicker:
        return returnDateTimeUTC;
    }
  }
);

export const selectStartUTCThreshold = createSelector(
  selectCurrentState,
  selectOutboundDateTimeUTC,
  (currentState, outboundDateTimeUTC): ToknUTC => {
    switch (currentState) {
      case AppGlobalStates.OutboundDateTimePicker:
        let currDayJs = dayjs(undefined).tz("Europe/London");
        return currDayJs.format("YYYY-MM-DDTHH:mm:ss");
      case AppGlobalStates.ReturnDateTimePicker:
        let currOutDayJs = dayjs(outboundDateTimeUTC).tz("Europe/London");
        currOutDayJs = currOutDayJs.add(1, "minute");
        //this is to make sure atleast return dates are 1 minute greater than outbound
        return currOutDayJs.format("YYYY-MM-DDTHH:mm:ss");
    }
  }
);
//As railcard array will have either a empty object or the filled railcards so refer to the first element which can decide if the railcards are there are not
