import React from "react";

type Props = {
  className: string;
};

function AddViaAvoid({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      className={className}
    >
      <g transform="translate(1.5 1.5)">
        <g data-name="Group 1196">
          <path
            fill="none"
            stroke="#1e1e50"
            strokeWidth="3"
            d="M16 0A16 16 0 110 16 16 16 0 0116 0z"
            data-name="Path 1560"
          ></path>
        </g>
        <path
          fill="#1e1e50"
          d="M5.879 0v5.879H0v2.4h5.879v5.879h2.4V8.284h5.879v-2.4H8.283V0z"
          data-name="Path 1095"
          transform="rotate(90 7.08 15.971)"
        ></path>
      </g>
    </svg>
  );
}

export default AddViaAvoid;
