import React from "react";

// Styles
import styles from "./Header.module.scss";

import SeLogo from "../../assets/se-logo.svg?react";
import { useSelector } from "react-redux";
import { selectIsHome } from "../../selectors/app";
import HeaderStepper from "../HeaderStepper/HeaderStepper";
import { useTranslation } from "react-i18next";
import ToknLogo from '../../assets/kadfire-tokn-mono.svg?react';

export default function Header() {
  const isHome: boolean = useSelector(selectIsHome);
  const { t } = useTranslation();

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.logoClockWrapper}>
        {/* <SeLogo style={{ padding: '15px' }} /> */}
 
        <ToknLogo style={{ padding: '15px',width : '260px', height : '80px'  }} />
      </div>

      <div className={styles.title}>
        {isHome ? (
          <header>
            <span className={styles.headTitle}>{t("home.title")}</span>
            <br />
            <span style={{ color: "#00AAEF" }}>eTickets</span>
          </header>
        ) : (
          <HeaderStepper />
        )}
      </div>
    </div>
  );
}
