//Best Blogs to refer for timelines
//https://freefrontend.com/react-js-timelines/
////https://codepen.io/TimRuby/pen/KKKeNz
//https://www.npmjs.com/package/react-horizontal-timeline
//https://github.com/Proskynete/vertical-timeline-component-react Wonderful Component for vertical timeline

import React, { useCallback, useState } from "react";
import styles from "./JourneyInfoModal.module.scss";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { TimeLineProgress } from "../TimeLineProgress/TimeLineProgress";
import { TravelSegment } from "../../types/journeyResults";
import { useJourneyInfoAPI } from "./JourneyInfoModal.hook";
import { useSelector } from "react-redux";
import {
  selectArrivalStation,
  selectDepartureStation,
} from "../../selectors/journeyPlanner";
import { Station, ToknDate } from "../../types/journeyPlanner";
import { selectDateTime } from "../../selectors/journeyResults";

type JourneyInfoProps = {
  journeyId: string;
  travelSegments: Array<TravelSegment>;
};

export function JourneyInfoModal({
  journeyId,
  travelSegments,
}: Readonly<JourneyInfoProps>): React.JSX.Element {
  const origin: Station = useSelector(selectDepartureStation);
  const destination: Station = useSelector(selectArrivalStation);
  const date: ToknDate = useSelector(selectDateTime);

  const [openModal, setOpenModal] = useState(false);

  const { data, loading, error } = useJourneyInfoAPI(journeyId, travelSegments);

  const handleClose = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleOnJourneyInfoClick = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  return (
    <>
      <div className={styles.button} onClick={handleOnJourneyInfoClick}>
        Journey Info &#62;
      </div>
      <Modal open={openModal}>
        <div className={styles.heading}></div>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <div className={styles.travelStatus}>
              <div className={styles.stationNames}>
                {origin.name} <span>&rarr; </span>
                {destination.name}
              </div>
              <div>{` ${date.dayString} / ${date.monthString} / ${date.year} `}</div>

              {data.map((segmentInfo, index) => {
                return (
                  <React.Fragment key={index}>
                    <div>
                      {travelSegments[index].operator.name ? (
                        <>
                          {"Service operated by  "}
                          <b>{travelSegments[index].operator.name}</b>
                        </>
                      ) : (
                        <b>{travelSegments[index].type}</b>
                      )}
                    </div>
                    <TimeLineProgress segmentInfo={segmentInfo} />
                  </React.Fragment>
                );
              })}
            </div>
            <Button data="Ok" onCloseFunc={handleClose} />
          </>
        )}
      </Modal>
    </>
  );
}
