import React from "react";

type Props = {
  onClick?: () => void;
};

function Back({ onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 36 36"
      onClick={onClick}>
      <path
        fill="#fff"
        d="M18 0A18 18 0 110 18 18 18 0 0118 0z"
        data-name="Path 1283"></path>
      <path
        fill="#1e1e50"
        d="M7.826 0L0 7.826l1.4 1.4 6.426-6.454L14.25 9.2l1.4-1.4z"
        transform="rotate(-90 18.864 6.962)"></path>
    </svg>
  );
}

export default React.memo(Back);
