//Version 2.0 App.ts
//Maintains the intial state and reducer code
import * as constants from "../actions/constants";
import {
  ActionTypes,
  ApiConfig,
  AppGlobalStates,
  AppState,
} from "../types/app";


export const api: ApiConfig = {
  credentials: {
    client_id: 'CLzCSmv20U0ushzSiFk96YG9u1eVp56F',
    client_secret: 'a33YRtWVQNuupIZG7r0jo-Zp6wo9wsrgWxxuw8HuYTeljgc2JjoaZFw_mkDHaqyw',
    audience: process.env.REACT_APP_TOKN_API_AUDIENCE || '',
    grant_type: 'client_credentials',
  },
  url: process.env.REACT_APP_TOKN_API_URL ?? '',
  authUrl: process.env.REACT_APP_TOKN_AUTH_URL ?? '',
  token: {
    access_token:
      'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjAtQkJKOVRwZUxad3R2ZmJEUkNiRiJ9.eyJ0aXMiOiJvbnRyYWNrIiwicHNwIjoib250cmFjayIsImlzcyI6Imh0dHBzOi8vdG9rbi51ay5hdXRoMC5jb20vIiwic3ViIjoiQ0x6Q1NtdjIwVTB1c2h6U2lGazk2WUc5dTFlVnA1NkZAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vZGV2LmFwaS50b2stbi51ayIsImlhdCI6MTcxMjA0NTEwMSwiZXhwIjoxNzE0NjM3MTAxLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMiLCJhenAiOiJDTHpDU212MjBVMHVzaHpTaUZrOTZZRzl1MWVWcDU2RiJ9.TM-1poJ91-CuLXheNXV8HBb6JMnhIvfkeXqno8j8qVhqO_Tt0jAXJ8mqdpx11nN0WE5AviVYTtIevld_LzG49AVDurdwa7Ta7okven_WG8mMXgBWA4ynSoDD4rtR2s4bRcxAZWpz5_kk17SvUFcQiWur72ljQva-p9xMjFJgGGZ6PWy4DwUGy4_ulNhPpBxoWnBsh4uFzgMW-CUNn1a7nRXkNcO2gAVcrZCVcSoTvrvix7YAviYEPCcvRhyxacPgRIijvgHPfrG1yi-DFxXZjJy9P4v-8jd0LI83sQF_V2XODqJ-Jg6hOYwi-RMJBl1RtuhHkzCK8bXhSwC_12Rwjw',
    token_type: 'Bearer',
  },
};

console.log(process.env);
console.log(api);

const initialState: AppState = {
  ready: false,
  environment: process.env.REACT_ENVIRONMENT || "",
  currentState: AppGlobalStates.Default,
  modal: false,
};

const app = (state = initialState, action: ActionTypes): AppState => {
  switch (action.type) {
    case constants.INIT:
      return {
        ...state,
        ready: action.data as boolean,
      };

    case constants.RESET_HOME:
      return initialState;

    case constants.SET_CURRENT_STATE:
      return {
        ...state,
        currentState: action.data as AppGlobalStates,
      };
    default:
      return state;
  }
};

export default app;
