import React from "react";

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Language = React.forwardRef<SVGSVGElement, SVGRProps>((props, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...props}
      ref={ref}
    >
      <g fill="#00aaef" stroke="#fff" strokeWidth="1" data-name="Ellipse 118">
        <circle cx="24" cy="24" r="24" stroke="none"></circle>
        <circle cx="24" cy="24" r="23.5" fill="none"></circle>
      </g>
      <text
        fill="#fff"
        fontFamily="Inter-Bold, Inter"
        fontSize="20"
        fontWeight="700"
        transform="translate(10.285 31)"
      >
        <tspan x="0" y="0">
          EN
        </tspan>
      </text>
    </svg>
  );
});

export default Language;
